


<script>


/**
 * Basic Info Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";

import { getBrokerageApi } from '@/api/brokerage'



export default {

    emits: ['confirm', 'cancel'],

  
    components: {

    },

    validations: {
        cheque: {
            start: { required },
            stop: { required },
        },
    },



    data() {

        return {
            cheque: {
                cheque_bind_acount : '',
                start: "",
                stop: "",
            },
            bank_choice: {},
            accounts: []

        }

    },
    methods: {

        handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                this.$emit('confirm', this.cheque)
            }
        }

    },

    created() {

    },


    mounted() {


        this.bank_choice = new Choices('#bank', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        getBrokerageApi().configApi.account_list().then((res) => {
            this.accounts.push({
                value: res.data.trust.id,
                label: res.data.trust.name,
                ...res.data.trust
            })
            this.accounts.push({
                value: res.data.commission.id,
                label: res.data.commission.name,
                ...res.data.commission
            })
            this.accounts.push({
                value: res.data.pm_trust.id,
                label: res.data.pm_trust.name,
                ...res.data.pm_trust
            })
            this.accounts.push({
                value: res.data.general.id,
                label: res.data.general.name,
                ...res.data.general
            })

            this.bank_choice.setChoices(this.accounts)
        })


    }

};
</script>

<template>
    <div class="row">
        <form @submit.prevent="handleSubmit">
            <div clsss="row">
                <div class="mb-3">
                    <label for="bank" class="form-label">Bank Account</label>
                    <select class="form-control" data-trigger v-model="cheque.cheque_bind_acount" name="bank" id="bank"
                        select-one>
                        <option value=""></option>
                    </select>

                </div>
            </div>
            <div clsss="row">
                <div class="mb-3">
                    <label class="form-label">Start Cheque Number</label>
                    <input type="text" class="form-control" v-model="cheque.start" placeholder="Enter Cheque Start Number"
                        :class="{
                            'is-invalid':  $v.cheque.start.$error,
                        }" />
                    <div v-if=" !$v.cheque.start.required" class="invalid-feedback">
                        Cheque Start Number is required.
                    </div>
                </div>
                <div class="mb-3">
                    <label for="addcontact-designation-input" class="form-label">Cheque Stop Number</label>
                    <input type="text" v-model="cheque.stop" class="form-control" placeholder="Enter Cheque Stop Number"
                        :class="{
                            'is-invalid':
                                 $v.cheque.stop.$error,
                        }" />
                    <div v-if=" !$v.cheque.stop.required" class="invalid-feedback">
                        Cheque Stop Number is required.
                    </div>
                </div>
            </div>


            <div class="text-end mt-3">
                <b-button variant="light" class="w-sm" @click="$bvModal.hide('cheque-dialog')">Close</b-button>
                <b-button type="submit" variant="primary" class="w-sm ms-2">Add</b-button>
            </div>
        </form>
    </div>
</template>