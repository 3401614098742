<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getBrokerageApi } from '@/api/brokerage'



import SetupChequeDialog from './components/cheque_setup_dialog.vue'

/**
 * User list component
 */
export default {
  page: {
    title: "Cheque Management",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Cheque Management",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "Cheque",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ChequeNumber",
          sortable: true,
        },
        {
          key: "Status",
          sortable: true,
        },
        {
          key: "BankAccount",
          sortable: true,
        },
        {
          key: "Remark",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
      submitted: false,

      cheque: {
        start: "",
        stop: "",
      },
      searchKey: "",
      accounts: [],
      bank_choice: {}
    };
  },

  validations: {
    cheque: {
      start: { required },
      stop: { required },
    },
  },

  components: {
    Layout,
    PageHeader,
    SetupChequeDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {

  },
  methods: {

    queryList() {
      let data = {
        page: { page: this.currentPage, page_size: this.perPage }
      }

      if (this.searchKey) {
        data.cheque_number = this.searchKey
        data.page.page = 1
        this.currentPage = 1
      }

      if (this.cheque_number) {
        data.cheque_number = this.cheque_number
        data.page.page = 1
        this.currentPage = 1
      }

      getBrokerageApi().configApi.cheque_list(data).then((res) => {
        this.listingData = []
        res.data.map((o) => {

          this.listingData.push(o)
          this.totalRows = res.page.total;
        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },


    setup_cheque(c) {
     

      this.cheque.type = "START_STOP"
      this.cheque.start = c.start
      this.cheque.stop = c.stop
      this.cheque.cheque_bind_acount = c.cheque_bind_acount
      getBrokerageApi().configApi.cheque_setup(this.cheque).then((res) => {
        if (res.errCode == 0) {
          this.queryList()
          this.$alertify.message("Setup Cheque Success");
          this.$bvModal.hide('cheque-dialog')
        } else {
          this.$alertify.error("Failed to Update Account " + res.errCode);
        }
      })
      this.submitted = false;
    },

    onEnterSearch() {
      this.queryList();
    },


    get_bank(item) {
     
      if (item.bank_account_type == 'bankAccount_type1') {
        return "Trust"
      } else if (item.bank_account_type == 'bankAccount_type2') {
        return "Commisssion Trust"
      } else if (item.bank_account_type == 'bankAccount_type5') {
        return "Trust Term"
      } else if (item.bank_account_type == 'bankAccount_type4') {
        return "General Account"
      } else if (item.bank_account_type == 'bankAccount_type3') {
        return "PM Trust"
      } 
    },

    discard_cheque(cheque) {
      let data = { cheque_number: cheque.cheque_no }
      getBrokerageApi().configApi.cheque_discard(data).then((res) => {
        if (res.errCode == 0) {
          this.queryList()
          this.$alertify.message("Discard Cheque Success");
        } else {
          this.$alertify.error("Failed to Update Account " + res.errCode);
        }
      })
    }

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Cheque
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">

                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">

                  <div class="search-box col-md-6">
                    <div class="position-relative">
                      <input type="text" class="form-control bg-light border-light rounded" v-model="searchKey"
                        placeholder="Search by Cheque Number" @keyup.enter="onEnterSearch" />
                      <i class="uil uil-search search-icon"></i>
                    </div>
                  </div>

                  <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item v-b-modal.cheque-dialog>Add Cheque</b-dropdown-item>
                  </b-dropdown>
                  <!-- end dropdown -->
                  <b-modal id="cheque-dialog" title="Add Cheque" size="lg" centered hide-footer>
                    <SetupChequeDialog @cancel="$bvModal.hide('cheque-dialog')" @confirm="setup_cheque" />
                  </b-modal>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="listingData" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">

                  <template #cell(ChequeNumber)="data">
                    {{ data.item.cheque_no }}
                  </template>
                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(BankAccount)="data">
                    {{ get_bank(data.item) }}
                  </template>

                  
                  <template #cell(Remark)="data">
                    {{ data.item.remark }}
                  </template>


                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item :disabled="data.item.status == 'CACHED'" @click="discard_cheque(data.item)">Discard</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
